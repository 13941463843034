<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <v-card-title>
          <strong>Occupancy</strong>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>

          <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
            v-model="selected"
            :search="search"
            :headers="headers"
            :items="carParks"
            item-key="name"
            show-select
            class="elevation-1 table-one"
            multi-sort
        >
          <template v-slot:item.name="{item}">
            <div class="d-flex align-center">
              <p class="ma-0 font-weight-medium">
                {{ item.name }}
              </p>
            </div>
          </template>
          <template v-slot:item.occupancy="{item}">
            <div class="d-flex">

              <template>
                <v-progress-linear
                    :value="item.occupancy"
                    width="50"
                    height="10"
                    striped
                    color="deep-orange"
                />
              </template>
            </div>
          </template>
        </v-data-table>
      </base-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "Occupancy"
}
</script>

<style scoped>

</style>